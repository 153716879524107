import { Grid, Box, Link, Image, Text } from '@chakra-ui/react';
import Container from 'components/common/Container';
import { getInsta } from 'services/instagram';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import NextLink from 'next/link';
import ReactPlayer from 'react-player';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import FadeIn from 'components/transitions/FadeIn';

const InstagramContent = ({}) => {
  const router = useRouter();
  const [dataInsta, setdataInsta] = useState(null);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 4,
      slidesToSlide: 4, // optional, default to 1.
    },
    laptop: {
      breakpoint: { max: 992, min: 768 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 992, min: 576 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 576, min: 320 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  useEffect(() => {
    getInsta(router.locale).then((res) => {
      setdataInsta(res.data);
    });
  }, [router.locale]);

  return (
    <Container>
      <Text as={'h2'} mb={'20px'} fontSize={'36px'} fontWeight={'400'} textAlign="center" paddingTop="80px">
        <Box as={'span'}>{'Shop '}</Box>
        <Box as={'strong'}>{'Instagram '}</Box>
        <Box as={'span'} color="brand.900">
          {'#ΑnnaMariaMazaraki'}
        </Box>
      </Text>

      <FadeIn>
        {dataInsta && (
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={false}
            keyBoardControl={true}
            slidesToSlide={1}
            customTransition="all .4s linear"
            transitionDuration={500}
            containerClass="carousel-container"
            deviceType={'desktop'}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {dataInsta?.map(({ media_url, permalink, media_type }, index) => {
              return media_type === 'IMAGE' ? (
                <Box key={media_url} marginLeft={'5px'} cursor={'pointer'} onClick={() => router.push(permalink)}>
                  <Box pos="relative" width={'100%'} paddingTop={'150%'}>
                    <Image
                      pos={'absolute'}
                      top={'0'}
                      w="100%"
                      h="100%"
                      objectFit={'cover'}
                      src={media_url}
                      alt={'instagram'}
                    ></Image>
                  </Box>
                </Box>
              ) : (
                <Box marginLeft="5px" className="video-instagram" key={`instaVideo-${index}`}>
                  <Box cursor="pointer" onClick={() => router.push(permalink)}>
                    <Box pos="relative" minHeight="390px" width={'100%'} paddingTop={'150%'}>
                      <Box pos={'absolute'} top={'0'} w={'100%'} h={'100%'} objectFit={'cover'}>
                        <ReactPlayer
                          url={media_url}
                          loop={false}
                          playing={false}
                          controls={true}
                          volume={0}
                          width="100%"
                          height="100%"
                          playsinline={true}
                          muted={true}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Carousel>
        )}
      </FadeIn>
    </Container>
  );
};

export default InstagramContent;
